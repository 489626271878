.App {
  text-align: center;
  background-color: rgb(250, 250, 250);
}

img.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}

img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.gridPadding{
  padding: 10px;
}

.padBar{
  width: 100%;
}

.titleText{
  font-family: 'Courier New', Courier, monospace;
  font-weight: 400;
  font-size: calc(10px + 4vmin);
  font-stretch: semi-expanded;
}
.sideText{
  visibility: hidden;
  cursor: pointer;
}

.imgBoxWidth{
  height: 16vh;
  width: 16vh;
  object-fit: cover;
}

.imgBoxWidth:hover{
  opacity: .5y;
}

@media (min-width: 500px) {
  .imgBoxWidth{
    height: 20vw;
    width: 20vw;
    object-fit: cover;
  }
  .sideText{
    font-weight: 200;
    visibility: visible;
  }
}

.imgBar{
  min-height: 45vh;
  max-height: 45vh;
  align-items: center;
  justify-content: center;
  padding-right: 2vw;
  padding-left: 2vw;

}

.masterPadding{
  margin-top: 20px;
  margin-bottom: 20px;
}

.contactBar{
  min-height: 10vh;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(250, 250, 250);
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100wh;
  min-width: 100wh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(9, 9, 9);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
